let OSS = require('ali-oss');

let client = new OSS({
	region: 'oss-cn-shanghai',
	accessKeyId: 'LTAI5tPQjRJyd46AFJPVk6Ch',
	accessKeySecret: 'kKEO3UpLk5n6V9h0vMYAJ2q3NQXfR9',
	bucket: 'c-floral'
});

export function download(objectName, fileName, suffixName) {
	let url = client.signatureUrl(objectName, 
	{
		response: {
			'content-disposition': `attachment; filename="${fileName}.${suffixName}"`
		}
	})

	return url
}
